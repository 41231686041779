import React from 'react'

import Layout from '../../components/Layout'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import Dialog from '@material-ui/core/Dialog';

import image1 from '../../img/gallery/gallery1.jpg'
import image2 from '../../img/gallery/gallery2.jpg'
import image3 from '../../img/gallery/gallery3.jpg'
import image4 from '../../img/gallery/gallery4.jpg'

import image5 from '../../img/gallery/gallery5.jpg'
import image6 from '../../img/gallery/gallery6.jpg'
import image7 from '../../img/gallery/gallery7.jpg'
import image8 from '../../img/gallery/gallery8.jpg'

import image9 from '../../img/gallery/gallery9.jpg'
import image10 from '../../img/gallery/gallery10.jpg'
import image11 from '../../img/gallery/gallery11.jpg'
import image12 from '../../img/gallery/gallery12.jpg'

import image13 from '../../img/gallery/gallery13.jpg'
import image14 from '../../img/gallery/gallery14.jpg'
import image15 from '../../img/gallery/gallery15.jpg'
import image16 from '../../img/gallery/gallery16.jpg'

import image17 from '../../img/gallery/gallery17.jpg'
import image18 from '../../img/gallery/gallery18.jpg'
import image19 from '../../img/gallery/gallery19.jpg'
import image20 from '../../img/gallery/gallery20.jpg'

import image21 from '../../img/gallery/gallery21.jpg'
import image22 from '../../img/gallery/gallery22.jpg'

class GalleryPage extends React.Component {

  state = {
    tileData: [
      { img: image1, cols: 2, title: 'image1' },
      { img: image2, cols: 1, title: 'image2' },
      { img: image3, cols: 1, title: 'image3' },
      { img: image4, cols: 2, title: 'image4' },
      { img: image5, cols: 1, title: 'image5' },
      { img: image6, cols: 1, title: 'image6' },
      { img: image7, cols: 1, title: 'image7' },
      { img: image8, cols: 2, title: 'image8' },
      { img: image9, cols: 1, title: 'image9' },
      { img: image10, cols: 1, title: 'image10' },
      { img: image11, cols: 2, title: 'image11' },
      { img: image12, cols: 1, title: 'image12' },
      { img: image13, cols: 1, title: 'image13' },
      { img: image14, cols: 1, title: 'image14' },
      { img: image15, cols: 2, title: 'image15' },
      { img: image16, cols: 1, title: 'image16' },
      { img: image17, cols: 1, title: 'image17' },
      { img: image18, cols: 1, title: 'image18' },
      { img: image19, cols: 1, title: 'image19' },
      { img: image20, cols: 1, title: 'image20' },
      { img: image21, cols: 2, title: 'image21' },
      { img: image22, cols: 3, title: 'image22' }
    ],
    open: false,
    previewImg: null
  }

  handlePreview = (e) => {
    console.log(e.target.src);
    this.setState({
      open:!this.state.open,
      previewImg: e.target.src
    })
  }

  
  render() {
    const { data } = this.props
    const newImages = data.allMarkdownRemark.nodes;
    let col = 1;
    return (
      <Layout isProgramTypeSelected= {true}>
        <section className="section-gallery">
          <div className="gallery-wrap">
          <GridList cellHeight={250} className={"grid-list"} style={{marginBottom: '2px'}} cols={3}>
              {newImages.map((tile,index) => (
                tile.frontmatter.galleryimage != null && ( (newImages.length%3 == 1 ? (col = 3):( null)) || (newImages.length%3 == 2 ? (col = 3):( null)) || (newImages.length%3 == 0 ? (col = 1):( null)) ) ? ( 
            
                <GridListTile key={tile.img} cols={1}>
                  <picture>
                     <img className="gallery-img" src={`${tile.frontmatter.galleryimage.childImageSharp.fluid.src}`} onClick={this.handlePreview} />
                  </picture>
                 
                </GridListTile>
                ) 
                : (null)
              ))}
            </GridList>
            <GridList cellHeight={250} className={"grid-list"} cols={3}>
              {this.state.tileData.map((tile) => (
                <GridListTile key={tile.img} cols={tile.cols || 1}>
                  <img className="gallery-img" src={tile.img} alt={"sesquipedalian event"} onClick={this.handlePreview} />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <div>
          <Dialog className="p-0 gallery-popup" onClose={this.handlePreview} aria-labelledby="customized-dialog-title" open={this.state.open}>

              <img src={this.state.previewImg}></img>
          
          </Dialog>
        </div>
        </section>

      </Layout>
    )
  }

}

GalleryPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query GalleryImageQuery {
        allMarkdownRemark (sort: { order: ASC, fields: [frontmatter___galleryimage___atime] }) {
          nodes {
            frontmatter {
              galleryimage {
                absolutePath
                childImageSharp {
                  fluid(quality: 100) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <GalleryPage data={data}/>}
  />
)

